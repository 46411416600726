import antdArKW from 'antd/es/locale/ar_EG';
import arMsg from "../locales/ar_KW.json";

const ArLang = {
  antd: antdArKW,
  locale: 'ar-KW',
  messages: {
    ...arMsg
  },
};
export default ArLang;
