export const GET_SCHOOL_DATA = 'GET_SCHOOL_DATA';
export const UPDATE_SCHOOL_DATA = 'UPDATE_SCHOOL_DATA';
export const GET_SCHOOL_YEAR = 'GET_SCHOOL_YEAR';
export const DELETE_SCHOOL_YEAR = 'DELETE_SCHOOL_YEAR';
export const UPDATE_SCHOOL_YEAR = 'UPDATE_SCHOOL_YEAR';
export const GET_SCHOOL_GRADE_TYPE = 'GET_SCHOOL_GRADE_TYPE';
export const DELETE_SCHOOL_GRADE_TYPE = 'DELETE_SCHOOL_GRADE_TYPE';
export const UPDATE_SCHOOL_GRADE_TYPE = 'UPDATE_SCHOOL_GRADE_TYPE';
export const GET_ROLE = 'GET_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const GET_GRADES = 'GET_GRADES';
export const GET_GRADES_STUDY_START_DATE = 'GET_GRADES_STUDY_START_DATE';
export const DELETE_GRADES = 'DELETE_GRADES';
export const UPDATE_GRADES = 'UPDATE_GRADES';
export const GET_JOBS = 'GET_JOBS';
export const DELETE_JOBS = 'DELETE_JOBS';
export const UPDATE_JOBS = 'UPDATE_JOBS';
export const GET_DOC_TYPES = 'GET_DOC_TYPES';
export const DELETE_DOC_TYPES = 'DELETE_DOC_TYPES';
export const UPDATE_DOC_TYPES = 'UPDATE_DOC_TYPES';
export const GET_SCHOOL_CLASS = 'GET_SCHOOL_CLASS';
export const DELETE_SCHOOL_CLASS = 'DELETE_SCHOOL_CLASS';
export const UPDATE_SCHOOL_CLASS = 'UPDATE_SCHOOL_CLASS';
export const GET_MASTER_FEES_LIST = 'GET_MASTER_FEES_LIST';
export const GET_FEESTYPE_LIST = 'GET_FEESTYPE_LIST';
export const GET_GRADE_FEES_LIST = 'GET_GRADE_FEES_LIST';
export const GET_PERCENTAGE_DISCOUNT_LIST = 'GET_PERCENTAGE_DISCOUNT_LIST';
export const GET_BROTHERPERCENTAGE_DISCOUNT_LIST = 'GET_BROTHERPERCENTAGE_DISCOUNT_LIST';
export const GET_SCHOOL_DEPARTMENTS = 'GET_SCHOOL_DEPARTMENTS';
export const UPDATE_SCHOOL_DEPARTMENT = 'UPDATE_SCHOOL_DEPARTMENT';
export const DELETE_SCHOOL_DEPARTMENT = 'DELETE_SCHOOL_DEPARTMENT';
export const GET_SCHOOL_SECTIONS = 'GET_SCHOOL_SECTIONS';
export const UPDATE_SCHOOL_SECTION = 'UPDATE_SCHOOL_SECTION';
export const DELETE_SCHOOL_SECTION = 'DELETE_SCHOOL_SECTION';
export const GET_SCHOOL_SUBJECTS = 'GET_SCHOOL_SUBJECTS';
export const GET_NEXT_YAER_SCHOOL_SUBJECTS = 'GET_NEXT_YAER_SCHOOL_SUBJECTS';
export const UPDATE_SCHOOL_SUBJECT = 'UPDATE_SCHOOL_SUBJECT';
export const DELETE_SCHOOL_SUBJECT = 'DELETE_SCHOOL_SUBJECT';
export const GET_SCHOOL_GRADING_DEFINITIONS = 'GET_SCHOOL_GRADING_DEFINITIONS';
export const UPDATE_SCHOOL_GRADING_DEFINITIONT = 'UPDATE_SCHOOL_GRADING_DEFINITION';
export const DELETE_SCHOOL_GRADING_DEFINITION = 'DELETE_SCHOOL_GRADING_DEFINITION';
export const GET_SCHOOL_TRACKS = 'GET_SCHOOL_TRACKS';
export const UPDATE_SCHOOL_TRACKS = 'UPDATE_SCHOOL_TRACKS';
export const DELETE_SCHOOL_TRACKS = 'DELETE_SCHOOL_TRACKS';
export const GET_SCHOOL_TERM_PERIOD = 'GET_SCHOOL_TERM_PERIOD';
export const UPDATE_SCHOOL_TERM_PERIOD = 'UPDATE_SCHOOL_TERM_PERIOD';
export const DELETE_SCHOOL_TERM_PERIOD = 'DELETE_SCHOOL_TERM_PERIOD';
export const GET_SCHOOL_SUBJECT_ASSESSMENTS = 'GET_SCHOOL_SUBJECT_ASSESSMENTS';
export const UPDATE_SCHOOL_SUBJECT_ASSESSMENTS = 'UPDATE_SCHOOL_SUBJECT_ASSESSMENTS';
export const DELETE_SCHOOL_SUBJECT_ASSESSMENTS = 'DELETE_SCHOOL_SUBJECT_ASSESSMENTS';
export const GET_SCHOOL_SUBJECT_TEACHERS = 'GET_SCHOOL_SUBJECT_TEACHERS';
export const UPDATE_SCHOOL_SUBJECT_TEACHERS = 'UPDATE_SCHOOL_SUBJECT_TEACHERS';
export const DELETE_SCHOOL_SUBJECT_TEACHERS = 'DELETE_SCHOOL_SUBJECT_TEACHERS';
export const GET_PEEMISSIONS = 'GET_PEEMISSIONS';
export const GET_ALL_GROUPS = 'GET_ALL_GROUPS'
export const GET_ALL_ICONS = 'GET_ALL_ICONS';
export const GET_STUDENTS_WITH_SKILL_MARKS = 'GET_STUDENTS_WITH_SKILL_MARKS';
export const GET_ABSENT_STUDENTS = 'GET_ABSENT_STUDENTS';
export const GET_FAIL_STUDENTS = 'GET_FAIL_STUDENTS';
export const GET_HONOR_STUDENTS = 'GET_HONOR_STUDENTS';
export const GET_WEAK_STUDENTS = 'GET_WEAK_STUDENTS';
export const GET_CANCELED_PAYMENT = 'GET_CANCELED_PAYMENT';
export const GET_BLOOD_TYPES = "GET_BLOOD_TYPES";
export const GET_SPECIALITIES = "GET_SPECIALITIES";
export const GET_SPECIALITIES_TYPES = "GET_SPECIALITIES_TYPES";
export const GET_MEDICINES = "GET_MEDICINES";
export const GET_ISSUES = "GET_ISSUES";
export const GET_CLINICS = "GET_CLINICS";
export const SET_CURRENT_DEFINITION = "SET_CURRENT_DEFINITION";
export const GET_ALL_LIBRARY_SECTIONS = "GET_ALL_LIBRARY_SECTIONS";
export const GET_ALL_LIBRARY_BOOKS = "GET_ALL_LIBRARY_BOOKS";
export const GETSCHOOL_GALLERY = "GETSCHOOL_GALLERY";
export const SET_CURRENT_LIBRARY_BOOK = "SET_CURRENT_LIBRARY_BOOK";
export const GET_STORE_MEDICINE = "GET_STORE_MEDICINE";
export const GET_CONFIRMED_SUBJECT_MARK = "GET_CONFIRMED_SUBJECT_MARK";
export const GET_GRDUATION_CERTIFICATION = "GET_GRDUATION_CERTIFICATION";
export const GET_NEXT_YEAR_SUBJECT_TEACHERS = "GET_NEXT_YEAR_SUBJECT_TEACHERS";
export const GET_NEXT_YEAR_TRACKS = 'GET_NEXT_YEAR_TRACKS';
export const GET_DAILY_PAYMENTS_WITH_PAYMENT_HISTORY = 'GET_DAILY_PAYMENTS_WITH_PAYMENT_HISTORY';
export const GET_ASSESSMENTS_SUBJECT = 'GET_ASSESSMENTS_SUBJECT';
export const GET_MARK_STATISTICS = 'GET_MARK_STATISTICS';
export const GET_STUDENT_MARKS = 'GET_STUDENT_MARKS';
export const GET_EXPECTED_STUDENT_COUNT = 'GET_EXPECTED_STUDENT_COUNT';
export const UPDATE_STUDENT_LIST_SUBJECT = 'UPDATE_STUDENT_LIST_SUBJECT';

