import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/admin`} component={lazy(() => import(`./admin`))} />
        <Route path={`${APP_PREFIX_PATH}/dynamic-report`} component={lazy(() => import(`./dynamic-report`))} />
        <Route path={`${APP_PREFIX_PATH}/human-resource`} component={lazy(() => import(`./human-resource`))} />
        <Route path={`${APP_PREFIX_PATH}/student-affairs`} component={lazy(() => import(`./student-affairs`))} />
        <Route path={`${APP_PREFIX_PATH}/student-account-area`} component={lazy(() => import(`./student-account-area`))} />
        <Route path={`${APP_PREFIX_PATH}/grading-system-config`} component={lazy(() => import(`./grading-system-config`))} />
        <Route path={`${APP_PREFIX_PATH}/lms-roles`} component={lazy(() => import(`./lms-roles`))} />
        <Route path={`${APP_PREFIX_PATH}/request`} component={lazy(() => import(`./request`))} />
        <Route path={`${APP_PREFIX_PATH}/webpage`} component={lazy(() => import(`./web-pages`))} />
        <Route path={`${APP_PREFIX_PATH}/clinics`} component={lazy(() => import(`./clinics`))} />
        <Route path={`${APP_PREFIX_PATH}/library`} component={lazy(() => import(`./library`))} />
        <Route path={`${APP_PREFIX_PATH}/store`} component={lazy(() => import(`./store`))} />
        <Route path={`${APP_PREFIX_PATH}/clothes`} component={lazy(() => import(`./clothes`))} />
        <Route path={`${APP_PREFIX_PATH}/account-tree`} component={lazy(() => import(`./account-tree`))} />
        <Route path={`${APP_PREFIX_PATH}/school-schedule`} component={lazy(() => import(`./school-schedule`))} />
        <Route path={`${APP_PREFIX_PATH}/school-supplies`} component={lazy(() => import('./school-supplies'))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>
    </Suspense>
  )
};

export default React.memo(AppViews);
