import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import test from './testReducer';
import adminArea from './AdminArea';
import Shared from './Shared';
import StudentArea from './StudentArea';
import EmployeeArea from './EmployeeArea';
import DynamicReportArea from './DynamicReportArea';
import FeesData from './Fees';
import LmsRolesArea from './LmsRolesArea';
import Dashboard from "./Dashboard";
import LandingPageArea from './LandingPageArea';
import StoreArea from './StoreArea';
import ClothesArea from './ClothesArea';
import AccountArea from './AccountArea'
import ScheduleArea from './ScheduleArea'
import SchoolSupplyArea from './SchoolSupplyArea.js'



const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    adminarea: adminArea,
    shared: Shared,
    studentArea: StudentArea,
    employeeArea: EmployeeArea,
    dynamicReportArea: DynamicReportArea,
    feesData: FeesData,
    lmsRolesArea: LmsRolesArea,
    test,
    dashboard: Dashboard,
    landingPageArea: LandingPageArea,
    storeArea: StoreArea,
    clothesArea: ClothesArea,
    accountArea: AccountArea,
    scheduleArea: ScheduleArea,
    schoolSupplyArea: SchoolSupplyArea 
});

// export default reducers;

const rootReducer = (state, action) => {
    if (action.type === 'SIGNOUT_SUCCESS') {
        state = undefined
    }
    return reducers(state, action)
};

export default rootReducer