import {
  GET_PURCHASED_STATIONERY,
  GET_STATIONERY_ITEMS,
  GET_STATIONERY_PROPOSAL,
  GET_STATIONERY_REQUEST,
  GET_STATIONERY_MOVMENT,
  GET_STATIONERY_COLLECTOR,
  GET_STATIONERY_INVENTORY,
  GET_STATIONERY_DISTRIBUTERS
} from "../constants/StationeryArea";

const initStateShared = {
  stationeryItems: [],
  purchasedStationery: {},
  stationeryRequests:{},
  stationeryProposals: {},
  stationeryMovmentReport: [],
  stationeryCollectortReport:{},
  stationeryInventorytReport:{},
  stationeryDistributers:{}
};

const test = (state = initStateShared, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STATIONERY_ITEMS:
      return {
        ...state,
        stationeryItems: payload,
      };
    case GET_PURCHASED_STATIONERY:
      return {
        ...state,
        purchasedStationery: payload,
      };
    case GET_STATIONERY_REQUEST:
      return {
        ...state,
        stationeryRequests: payload,
      };
    case GET_STATIONERY_PROPOSAL:
      return {
        ...state,
        stationeryProposals: payload,
      };
    case GET_STATIONERY_MOVMENT:
      return {
        ...state,
        stationeryMovmentReport: payload,
      };
    case GET_STATIONERY_COLLECTOR:
      return {
        ...state,
        stationeryCollectortReport: payload,
      };
    case GET_STATIONERY_INVENTORY:
      return {
        ...state,
        stationeryInventorytReport: payload,
      };
    case GET_STATIONERY_DISTRIBUTERS:
      return {
        ...state,
        stationeryDistributers: payload,
      };
      
      
    default:
      return state;
  }
};

export default test;
