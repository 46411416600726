export const GETALLPAGES = "GETALLPAGES"
export const GETALLPANNOUNCMENT = "GETALLPANNOUNCMENT"
export const GETALLACHIEVMENTS = "GETALLACHIEVMENTS"
export const GETALLABOUT_US_ITEMS = "GETALLABOUT_US_ITEMS"
export const GETALLMANAGER_WORDS = "GETALLMANAGER_WORDS"
export const GETALL_AP_COURSES = "GETALL_AP_COURSES"
export const GETALLCURRICULUMS = "GETALLCURRICULUMS"
export const GETALL_ADMISSIONS = "GETALL_ADMISSIONS"
export const GETALL_IMAGES = "GETALL_IMAGES"
export const GETALL_CONTACTS = "GETALL_CONTACTS"
export const GETALL_APPLICATIONS = "GETALL_APPLICATIONS"
export const GETALL_STUDENT_APPLICATIONS = "GETALL_STUDENT_APPLICATIONS"
export const GETALL_TECHNOLOGY = "GETALL_TECHNOLOGY"
export const GET_LATESTUPDATE_VIDEOS = "GET_LATESTUPDATE_VIDEOS"
export const GET_GALLERY = "GET_GALLERY"
export const GET_ALL_ALBUMS = "GET_ALL_ALBUMS"
export const GET_INTERNAL_PAGES = "GET_INTERNAL_PAGES"
export const GET_SMS_HISTORY = "GET_SMS_HISTORY"
export const UPDATE_READ_AT = "UPDATE_READ_AT"
export const GETALL_APPLICATIONS_DATA = "GETALL_APPLICATIONS_DATA"






