export const GET_SCHOOL_CALENDER = 'GET_SCHOOL_CALENDER';
export const UPDATE_SCHOOL_CALENDER = 'UPDATE_SCHOOL_CALENDER';

export const GET_NOTIFICATIONS_ANNOUNCEMENT = 'GET_NOTIFICATIONS_ANNOUNCEMENT';
export const UPDATE_NOTIFICATIONS_ANNOUNCEMENT = 'UPDATE_NOTIFICATIONS_ANNOUNCEMENT';

export const GET_NOTIFICATIONS_SMS = 'GET_NOTIFICATIONS_SMS';
export const UPDATE_NOTIFICATIONS_SMS = 'UPDATE_NOTIFICATIONS_SMS';

export const GET_NOTIFICATIONS_MOBILE = 'GET_NOTIFICATIONS_MOBILE';
export const GET_NOTIFICATIONS_MOBILE_TOTALS_STUDENTS = 'GET_NOTIFICATIONS_MOBILE_TOTALS_STUDENTS';
export const GET_NOTIFICATIONS_MOBILE_TOTALS_STUDENT_DETAILS = 'GET_NOTIFICATIONS_MOBILE_TOTALS_STUDENT_DETAILS';
export const UPDATE_NOTIFICATIONS_MOBILE = 'UPDATE_NOTIFICATIONS_MOBILE';

export const GET_TIME_TABLE = 'GET_TIME_TABLE';
export const UPDATE_TIME_TABLE = 'UPDATE_TIME_TABLE';

export const GET_COURSE_PLAN = 'GET_COURSE_PLAN';
export const UPDATE_COURSE_PLAN = 'UPDATE_COURSE_PLAN';

export const GET_SHORT_REPORT = 'GET_SHORT_REPORT';
export const UPDATE_SHORT_REPORT = 'UPDATE_SHORT_REPORT';
export const GET_NOTIFICATION_BY_TEACHER = 'GET_NOTIFICATION_BY_TEACHER';
