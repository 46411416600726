import {
  AUTH_TOKEN,
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  HIDE_LOADING
} from '../constants/Auth';

import axiosinstance from '../../auth/AxiosIntance'
import Utils from 'utils';
import { SESSION_STATUS } from 'redux/constants/Auth';

// For SignIn
export function signIn(data) {
  return async dispatch => {
    try {
      const response = await axiosinstance.post('auth/login', data);
      const responseJson = response.data;
      if (responseJson.status) {
        dispatch({
          type: SIGNIN,
          payload: responseJson,
          token: responseJson.token
        });
        Utils.setCookie(SESSION_STATUS, true, 8/24);
        localStorage.setItem(AUTH_TOKEN, responseJson.token);
        authenticated(responseJson.token);
        return Promise.resolve({msg: responseJson.msg,status:true, user: responseJson.user});
      } else {
        return Promise.resolve({msg: responseJson.msg,status:false});
      }
    }
    catch (e) {
      console.log("catch error API SIGNIN", e);
      if(e.message === 'Network Error'){
        return Promise.reject({msg:'لايوجد اتصال بالانترنت' ,status:false});
      }else {
          console.log("catch error API", e)
      }
    }
  }
}

export const authenticated = (token) => {
  return async dispatch => {
    try {
      // localStorage.removeItem(AUTH_TOKEN);
      // authenticated('')
      dispatch({
        type: AUTHENTICATED,
        token
      })
    }
    catch (e) {
      console.log("catch error API authenticated", e)
    }
  }
};


export const signOut = () => {
  return async dispatch => {
    try {
      Utils.setCookie(SESSION_STATUS, '', Date.now());
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem('locale');
      localStorage.removeItem('position');
      authenticated('');
      dispatch({
        type: SIGNOUT_SUCCESS
      })
    }
    catch (e) {
      console.log("catch error API signOut", e)
    }
  }
};

export const showLoading = () => {
  return async dispatch => {
    try {
      dispatch({
        type: SHOW_LOADING,
      })
    }
    catch (e) {
      console.log("catch error API signOut", e)
    }
  }
  // return {
  //   type: SHOW_LOADING,
  // };
};

export const hideLoading = () => {
  return async dispatch => {
    try {
      dispatch({
        type: HIDE_LOADING,
      })
    }
    catch (e) {
      console.log("catch error API signOut", e)
    }
  }
};

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE
  };
};

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};
