import {
    GETALLPAGES,
    GETALLPANNOUNCMENT,
    GETALLACHIEVMENTS,
    GETALLABOUT_US_ITEMS,
    GETALLMANAGER_WORDS,
    GETALL_AP_COURSES,
    GETALLCURRICULUMS,
    GETALL_ADMISSIONS,
    GETALL_IMAGES,
    GETALL_CONTACTS,
    GETALL_APPLICATIONS,
    GETALL_STUDENT_APPLICATIONS,
    GETALL_TECHNOLOGY,
    GET_LATESTUPDATE_VIDEOS,
    GET_GALLERY,
    GET_ALL_ALBUMS,
    GET_INTERNAL_PAGES,
    GET_SMS_HISTORY,
    UPDATE_READ_AT,
    GETALL_APPLICATIONS_DATA
} from '../constants/LandingPage';

const initState = {
    pages: [],
    announcments: [],
    achievments: [],
    aboutusItems: [],
    managerWords: [],
    courses: [],
    curriculums: [],
    admissions: [],
    images: [],
    contacts: [],
    applications: {},
    student_applications: [],
    our_technology: [],
    latest_update_videos: [],
    gallery: [],
    albums: [],
    internalPages:[],
    sms_history:[],
    applicationData:{}
};

const landingpage = (state = initState, action) => {
    switch (action.type) {
        case GETALLPAGES: {
            return {
                ...state,
                pages: action?.payload || [],
            };
        }
        case GETALLPANNOUNCMENT: {
            return {
                ...state,
                announcments: action?.payload.result || [],
            };
        }
        case GETALLACHIEVMENTS: {
            return {
                ...state,
                achievments: action?.payload.result || [],
            };
        }
        case GETALLABOUT_US_ITEMS: {
            return {
                ...state,
                aboutusItems: action?.payload.result || [],
            };
        }
        case GETALLMANAGER_WORDS: {
            return {
                ...state,
                managerWords: action?.payload.result || [],
            };
        }
        case GETALL_AP_COURSES: {
            return {
                ...state,
                courses: action?.payload.result || [],
            };
        }
        case GETALLCURRICULUMS: {
            return {
                ...state,
                curriculums: action?.payload.result || [],
            };
        }
        case GETALL_ADMISSIONS: {
            return {
                ...state,
                admissions: action?.payload.result || [],
            };
        }
        case GETALL_IMAGES: {
            return {
                ...state,
                images: action?.payload.result || [],
            };
        }
        case GETALL_CONTACTS: {
            return {
                ...state,
                contacts: action?.payload.result || [],
            };
        }
        case GETALL_APPLICATIONS: {
            return {
                ...state,
                applications: action?.payload || [],
            };
        }
        case GETALL_STUDENT_APPLICATIONS: {
            return {
                ...state,
                student_applications: action?.payload || [],
            };
        }
        case GETALL_TECHNOLOGY: {
            return {
                ...state,
                our_technology: action?.payload.result || [],
            };
        }
        case GET_LATESTUPDATE_VIDEOS: {
            return {
                ...state,
                latest_update_videos: action?.payload.result || [],
            };
        }
        case GET_GALLERY: {
            return {
                ...state,
                gallery: action?.payload.result || [],
            }
        }
        case GET_ALL_ALBUMS: {
            return {
                ...state,
                albums: action?.payload.result || [],
            }
        }
        case GET_INTERNAL_PAGES: {
            return {
                ...state,
                internalPages: action?.payload.result || [],
            }
        }
        case GET_SMS_HISTORY:{
            return {
                ...state,
                sms_history: action?.payload || [],
            }
        }
        case UPDATE_READ_AT:{
            const newContacts = state?.contacts?.map(el=> {
                if(el?.id === action.payload?.id){
                    return {...el, read_at: new Date()}
                }return el
            })
            return {
                ...state,
                contacts: newContacts,
            }
        }
        case GETALL_APPLICATIONS_DATA:{
            return {
                ...state,
                applicationData: action?.payload?.result
            }
        }
        default:
            return state;
    }
};

export default landingpage;
