import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import AppLocale from 'lang';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import useBodyClass from 'hooks/useBodyClass';
import { DIR_LTR, DIR_RTL } from 'constants/ThemeConstant';
import '../index.css';
import '../scroll.css';
import ErrorBoundary from 'components/shared-components/ErrorBoundary';
import Utils from 'utils';
import {  SESSION_STATUS } from 'redux/constants/Auth';

function RouteInterceptor({ children, isAuthenticated, userData, ...rest }) {
  const permissions = [];
  userData?.roles?.groups
    .map((g) => g.PermissionsPages)
    ?.forEach((i) => i.forEach((m) => permissions.push(m)));
  const url = window.location.href.replace(/\?.+/g, "").split('/');
  let urlSlug = url?.at(-1);
  if(!urlSlug || !isNaN(urlSlug)){
    urlSlug = url?.at(-2)
  }  
  let hasAccess =
    permissions.findIndex(
      (permission) => permission.slug === urlSlug
    ) !== -1 || userData.is_admin === 1;

  if (urlSlug === 'default' || !userData?.id) {
    hasAccess = true;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated && hasAccess) {
          return children;
        } else if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: AUTH_PREFIX_PATH,
                state: { from: location },
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: '/app/dashboards/default',
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
}

export const Views = (props) => {
  const { locale, location, userData } = props;
  const currentAppLocale = AppLocale[locale];
  const isAuthenticated = Utils.checkCookie(SESSION_STATUS);
  const newDirection = locale === 'ar' ? DIR_RTL : DIR_LTR;
  useBodyClass(`dir-${newDirection}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ErrorBoundary>
      <ConfigProvider locale={currentAppLocale.antd} direction={newDirection}>
        <Switch>
          <Route exact path='/'>
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={newDirection} />
          </Route>
          <RouteInterceptor
            path={APP_PREFIX_PATH}
            isAuthenticated={isAuthenticated}
            userData={userData}
          >
            <AppLayout direction={newDirection} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
      </ErrorBoundary>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token, userData } = auth;
  return { locale, direction, token, userData };
};

export default withRouter(connect(mapStateToProps)(Views));
