import axios from 'axios'

// import { API_BASE_URL } from 'configs/AppConfig'
import { API_BASE_URL } from '../constants/ApiConstant'
// import history from '../history'
// import { AUTH_TOKEN } from 'redux/constants/Auth'
import { AUTH_TOKEN } from 'redux-thunk/constants/Auth'
import { signOut } from 'redux-thunk/actions/Auth'
import { store } from 'redux-thunk/store';
import Utils from 'utils';



const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 1 * 60 * 60 * 1000,
})


const requestHandler = request => {

    // Token will be dynamic so we can use any app-specific way to always   
    // fetch the new token before making the call
    // const token = localStorage.getItem(AUTH_TOKEN);
    const token = localStorage.getItem(AUTH_TOKEN);
    const locale = localStorage.getItem('locale');
    // console.log("lOcale ====>", locale);
    if (token != null) {
        request.headers['x-access-token'] = token;
    }
    request.headers['locale'] = locale
    request.headers['Access-Control-Allow-Origin'] = '*'
    request.headers['Accept']= 'application/json'


    return request;
};

const responseHandler = (response) => {
    const { dispatch } = store
    if (response.status == 401 || response.status == 403) {
        // window.location = '/auth/login';
        // localStorage.removeItem(AUTH_TOKEN)
        dispatch(signOut())
    }
    return response;
};


const errorHandler = error => {
    console.log("Error ------- ", error?.response);
    if (error?.response?.status == 401 || error?.response?.status == 403) {
        responseHandler(error?.response);
    }
    return Promise.reject(error);
};

service.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);
service.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);
// service.interceptors.request.use(function () {/*...*/})
export default service;