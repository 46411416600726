import {gradingPoints} from "../adminStaticData";
import React from "react";
import moment from "moment";
import {numberArabicWords} from "../stu_emp_staticData";

class Utils {

  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    let initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree.path === path) {
      return navTree;
    }
    let route;
    for (let p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object') {
        route = this.getRouteInfo(navTree[p], path);
        if (route) {
          return route;
        }
      }
    }
    return route;
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    if (!hex) {
      return 'dark'
    }
    const threshold = 130;
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);

    function hexToR(h) {
      return parseInt((cutHex(h)).substring(0, 2), 16)
    }

    function hexToG(h) {
      return parseInt((cutHex(h)).substring(2, 4), 16)
    }

    function hexToB(h) {
      return parseInt((cutHex(h)).substring(4, 6), 16)
    }

    function cutHex(h) {
      return (h.charAt(0) === '#') ? h.substring(1, 7) : h
    }

    const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    if (cBrightness > threshold) {
      return 'dark'
    } else {
      return 'light'
    }
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);
    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;
    const RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16));
    const GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16));
    const BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16));
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Convert RGBA to HEX
   * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
   * @return {String} HEX color
   */
  static rgbaToHex(rgba) {
    const trim = str => (str.replace(/^\s+|\s+$/gm, ''));
    const inParts = rgba.substring(rgba.indexOf("(")).split(","),
      r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10),
      a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
    const outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(a * 255).toString(16).substring(0, 2)
    ];

    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = '0' + part;
      }
    });
    return (`#${outParts.join('')}`);
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive
    }
    if (number < 0) {
      return negative
    }
    return null
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      return a[key] - b[key]
    }

    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
    return null;
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter(item => item[key] === value)
    }
    return data
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter(item => item[key] !== value)
    }
    return data
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (let key in item) {
        if (item[key] == null) {
          continue;
        }
        if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
          return true;
        }
      }
    };
    list = list.filter(value => searchText(value));
    return list;
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    let breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key)
        }
      }
    }
    return breakpoints
  }

  static createUUID = () => {
    let u = '', i = 0;
    while (i++ < 36) {
      const c = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'[i - 1], r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      u += (c === '-' || c === '4') ? c : v.toString(16)
    }
    return u;
  };

  static calcEquation(equations, operatorInputList, unitInputList, numbersInputList) {

    let finalResult = [0];
    let finalResultKey = 0;
    let operator = '';

    for (let i = 0; equations.length > i; i++) {

      const operators = ['+', '-', '*', '/', '='];
      let operatorInArray = operatorInputList.find(e => e.id === equations[i].id) || null;
      const isOperator = operatorInArray ? operators.includes(operatorInArray.unit_name) : null;
      let unitInArray = unitInputList.find(e => e.id === equations[i].id) || null;
      let numberInArray = numbersInputList.find(e => e.id === equations[i].id) || null;
      let number = unitInArray ? unitInArray.mark : numberInArray ? numberInArray.unit_name : equations[i].id;
      const isNumber = !isNaN(number);

      if (isOperator) {
        operator = operatorInArray.unit_name;
        if (operator === '=') {
          finalResultKey++;
          finalResult[finalResultKey] = 0;
        }
      } else if (isNumber) {
        if (operator === '') {
          finalResult[finalResultKey] += parseFloat(number);
        } else {
          if (operator === '+') {
            finalResult[finalResultKey] += parseFloat(number);
          } else if (operator === '-') {
            finalResult[finalResultKey] -= parseFloat(number);
          } else if (operator === '*') {
            finalResult[finalResultKey] *= parseFloat(number);
          } else if (operator === '/') {
            finalResult[finalResultKey] /= parseFloat(number);
          }
        }
        operator = '';
      }
    }
    let result = 0;
    finalResult.map(i => result += i);
    return result
  }

  static calcActualEquation(equations, units) {
    let finalResult = [0];
    let finalResultKey = 0;
    let operator = '';
    for (let i = 0; equations?.length > i; i++) {
      let isOperator = false;
      let number = 0.00;
      if (equations[i].type === 1) {
        //unit
        const unitMark = units?.find(u => u.unitId === parseInt(equations[i].coefficient));
        number = unitMark?.mark;
        isOperator = false;
      } else if (equations[i].type === 2) {
        //Number
        isOperator = false;
        number = equations[i].coefficient;
      } else if (equations[i].type === 3) {
        // operator
        isOperator = true;
        operator = equations[i].coefficient;
      }
      const isNumber = !isNaN(number);
      if (isOperator) {
        if (operator === '=') {
          finalResultKey++;
          finalResult[finalResultKey] = 0;
        }
      } else if (isNumber) {
        if (operator === '') {
          finalResult[finalResultKey] += parseFloat(number);
        } else {
          if (operator === '+') {
            finalResult[finalResultKey] += parseFloat(number);
          } else if (operator === '-') {
            finalResult[finalResultKey] -= parseFloat(number);
          } else if (operator === '*') {
            finalResult[finalResultKey] *= parseFloat(number);
          } else if (operator === '/') {
            finalResult[finalResultKey] /= parseFloat(number);
          }
        }
        operator = '';
      }
    }
    let result = 0;
    finalResult.map(i => result += i);
    return {
      finalResults: finalResult.map(r => r.toFixed(2)),
      result: this.roundResultDecimals(result)
    };
  }

  static roundResultDecimals(result) {

    const decimals = ((Math.round(result * 100) / 100).toFixed(2) - Math.floor((Math.round(result * 100) / 100)).toFixed(2)).toFixed(2);
    let finalResult = (Math.round(result * 100) / 100).toFixed(2) - decimals;
    if (decimals >= 0 && decimals < 0.25) {
      finalResult += 0;
    } else if (decimals >= 0.25 && decimals < 0.75) {
      finalResult += 0.5;
    } else if (decimals >= 0.75 && decimals < 1) {
      finalResult += 1;
    }
    return finalResult.toFixed(2);
  }

  static certificateRoundResultDecimals(result) {
    if (result === null)
      return null;

    const decimals = ((Math.round(result * 100) / 100).toFixed(2) - Math.floor((Math.round(result * 100) / 100)).toFixed(2)).toFixed(2);
    let finalResult = (Math.round(result * 100) / 100).toFixed(2) - decimals;
    if (
      (result >= 23.5 && result < 24)
      ||
      (result >= 27.5 && result < 28)
      ||
      (result >= 31.5 && result < 32)
      ||
      (result >= 35.5 && result < 36)
    ) {
      if (decimals >= 0.5 && decimals <= 1) {
        finalResult += 1;
      } else {
        finalResult = parseFloat(result);
      }
    } else {
      finalResult = parseFloat(result);
    }

    return finalResult.toFixed(2);
  }

  static certificateRoundSemesterResultDecimals(result) {
    if (result === null)
      return null;
    const decimals = ((Math.round(result * 100) / 100).toFixed(2) - Math.floor((Math.round(result * 100) / 100)).toFixed(2)).toFixed(2);
    let finalResult = (Math.round(result * 100) / 100).toFixed(2) - decimals;
    if (
      (finalResult === 59)
      ||
      (finalResult === 62)
      ||
      (finalResult === 66)
      ||
      (finalResult === 69)
      ||
      (finalResult === 72)
      ||
      (finalResult === 76)
      ||
      (finalResult === 79)
      ||
      (finalResult === 82)
      ||
      (finalResult === 86)
      ||
      (finalResult === 89)
      ||
      (finalResult === 92)
      ||
      (finalResult === 96)
    ) {
      if (decimals >= 0.5 && decimals <= 1) {
        finalResult += 1;
      } else {
        finalResult = parseFloat(result);
      }
    } else {
      finalResult = parseFloat(result);
    }
    return finalResult.toFixed(2);
  }

  static arQuery = (query) => {
    return query
      .replace('أ', 'ا')
      .replace('إ', 'ا')
      .replace('آ', 'ا')
      .replace('ي', 'ى')
      .replace('ه', 'ة');
  };

  static FIRST_PERIODICAL_ID = 1;
  static SECOND_PERIODICAL_ID = 2;
  static THIRD_PERIODICAL_ID = 3;
  static FOURTH_PERIODICAL_ID = 4;
  static firstSemesterResult = (student, subjectId) =>
    this.certificateRoundSemesterResultDecimals(
      parseFloat(
        this.certificateRoundResultDecimals(
          student?.student_subject_unit_mark_total_2.find(
            (i) =>
              i.subject_id === subjectId &&
              i.periodical_id === this.FIRST_PERIODICAL_ID
          )?.mark || null
        )
      ) +
      parseFloat(
        student?.student_subject_unit_mark_total_2.find(
          (i) =>
            i.subject_id === subjectId &&
            i.periodical_id === this.SECOND_PERIODICAL_ID
        )?.mark || null
      )
    );
  static secondSemesterResult = (student, subjectId) =>
    this.certificateRoundSemesterResultDecimals(
      parseFloat(
        this.certificateRoundResultDecimals(
          student?.student_subject_unit_mark_total_2.find(
            (i) =>
              i.subject_id === subjectId &&
              i.periodical_id === this.THIRD_PERIODICAL_ID
          )?.mark || null
        )
      ) +
      parseFloat(
        student?.student_subject_unit_mark_total_2.find(
          (i) =>
            i.subject_id === subjectId &&
            i.periodical_id === this.FOURTH_PERIODICAL_ID
        )?.mark || null
      )
    );
  static endOfYearResult = (student, subjectId) =>
    this.certificateRoundSemesterResultDecimals(this.roundResultDecimals((
      this.totalSemesterResult(student, subjectId) / 2
    )));
  static totalSemesterResult = (student, subjectId) =>
    this.roundResultDecimals((
      (parseFloat(this.certificateRoundSemesterResultDecimals(this.firstSemesterResult(student, subjectId))) +
        parseFloat(this.certificateRoundSemesterResultDecimals(this.secondSemesterResult(student, subjectId))))
    ));
  static firstSemesterPoints = (student, subjects) => {
    let ifAll = true;
    let points = null;
    let totalCredit = 0.0;
    subjects
      ?.filter((subject) =>
        student?.StudentTracks?.find(el => el.SchoolTrack.academic_year_id == subjects[0]?.academic_year_id)?.SchoolTrack?.SchoolSubjects.map(
          (i) => i.id
        )?.includes(subject.id)
      )
      ?.filter((subject) => subject.is_essential === 1)
      .map(subject => {
        const subjectResult = this.firstSemesterResult(student, subject.id);
        if (subjectResult == null || isNaN(subjectResult)) {
          ifAll = false
        }
        const gradingPoint = gradingPoints.find(gradingPoint =>
          (subject.subject_system_type_id === null ? true : subject.subject_system_type_id == gradingPoint.subject_system_type_id)
          &&
          (parseFloat(subjectResult) >= parseFloat(gradingPoint.marks_from) && parseFloat(subjectResult) <= parseFloat(gradingPoint.marks_to))
        );
        if (gradingPoint) {
          points += Number(Math.round(gradingPoint.points * subject.subject_type_credit + 'e' + 2) + 'e-' + 2);
          totalCredit += parseFloat(subject.subject_type_credit);
          // console.log(subject.name + '- Subject Credit: ' + subject.subject_type_credit);
          // console.log(subject.name + '- Subject Grading Points: ' + gradingPoint.points);
          // console.log(subject.name + '- Subject Points Default: ' + gradingPoint.points * subject.subject_type_credit);
          // console.log(subject.name + '- Subject Points Round: ' + Number(Math.round(gradingPoint.points * subject.subject_type_credit + 'e' + 2) + 'e-' + 2));
          // console.log('--------------------------------------------');
        }
      });
    points = Number(Math.round(points + 'e' + 2) + 'e-' + 2).toFixed(2);
    // console.log('Total Points: ' + points);
    // console.log('Total Credit: ' + this.getTotalCredit(student, subjects));
    return ifAll ? isNaN((points / this.getTotalCredit(student, subjects))) || points === null ? '-' : (points / this.getTotalCredit(student, subjects)).toFixed(2) : '-';
  };

  static secondSemesterPoints = (student, subjects) => {
    let points = null;
    let totalCredit = 0.0;
    let hasEmptySubject = false;
    subjects
      ?.filter((subject) =>
        student?.StudentTracks?.find(el => el.SchoolTrack.academic_year_id == subjects[0]?.academic_year_id)?.SchoolTrack?.SchoolSubjects.map(
          (i) => i.id
        )?.includes(subject.id)
      )
      ?.filter((subject) => subject.is_essential === 1)
      .map(subject => {
        const subjectResult = this.secondSemesterResult(student, subject.id);
        if (isNaN(subjectResult)) {
          hasEmptySubject = true;
        }
        const gradingPoint = gradingPoints.find(gradingPoint =>
          (subject.subject_system_type_id === null ? true : subject.subject_system_type_id == gradingPoint.subject_system_type_id)
          &&
          (parseFloat(subjectResult) >= parseFloat(gradingPoint.marks_from) && parseFloat(subjectResult) <= parseFloat(gradingPoint.marks_to))
        );
        if (gradingPoint) {
          points += Number(Math.round(gradingPoint.points * subject.subject_type_credit + 'e' + 2) + 'e-' + 2);
          totalCredit += parseFloat(subject.subject_type_credit);
        }
      });
    if (hasEmptySubject) {
      return '-';
    }
    points = Number(Math.round(points + 'e' + 2) + 'e-' + 2).toFixed(2);
    return isNaN((points / this.getTotalCredit(student, subjects))) || points === null ? '-' : (points / this.getTotalCredit(student, subjects)).toFixed(2);
  };
  static getPercentByTotalCumulativeGPA = (totalCumulativeGPA) => {
    return (parseFloat(parseFloat(totalCumulativeGPA) + 1) * 20).toFixed(2);
  };

  static absentSubject = (student, subjects, periodicalId) => {
    let countOfAbsentSubject = 0;
    let absentSubject = 'دور ثاني في';
    absentSubject = absentSubject + '\n';
    let hasEmptySubject = false;
    const fail = 'راسب ويبقى للاعادة';
    if (Number(periodicalId) === 4) {

      if (student?.studentacademicyear?.year_status === 2) {
        return  fail;
      }
      subjects
        ?.filter((subject) =>
          student?.StudentTracks?.find(el => el.SchoolTrack.academic_year_id == subjects[0]?.academic_year_id)?.SchoolTrack?.SchoolSubjects.map(
            (i) => i.id
          )?.includes(subject.id)
        )
        ?.filter((subject) => subject.is_essential === 1)
        .map(subject => {
          const subjectEndOfYearResult = this.endOfYearResult(student, subject.id);
          if (isNaN(subjectEndOfYearResult)) {
            hasEmptySubject = true;
          } else {
            if (subjectEndOfYearResult < 60) {
              absentSubject +=  " " + subject.ar_name + ' / ' ;
              countOfAbsentSubject += 1;
            }
          }
        });
      if (hasEmptySubject) {
        return '----------------------------';
      }
      if (countOfAbsentSubject > 0 &&  countOfAbsentSubject < 4) {
        return  absentSubject.substring(0, absentSubject.length - 2);
      } else if (countOfAbsentSubject > 3) {
        return fail;
      } else {
        if (student?.studentacademicyear?.schoolgrade?.sort_order === 15){
          return {hide:true}
        }
        else{
          return 'ناجح ' + numberArabicWords[parseInt(student.studentacademicyear.schoolgrade.name.replace(/[^0-9]/g, '')) + 1];
        }
      }
    } else {
      return '----------------------------';
    }
  };

  static subjectCheckEmpty = (student, subjects, periodicalId, table) => {
    let hasEmptySubject = false;
    if (Number(periodicalId) === 4) {
      subjects
        ?.filter((subject) => subject.is_essential === 1)
        .map(subject => {
          if (student?.[table].filter(i => i.subject_id === subject.id && i.mark !== null).length !== 4) {
            hasEmptySubject = true;
          }
        });
      if (hasEmptySubject) {
        return '----------------';
      }
      return true;
    } else {
      return true;
    }
  };

  static absentSubjectMiddleG3G4 = (student, subjects, periodicalId, table) => {
    let countOfAbsentSubject = 0;
    let absentSubject = 'دور ثاني في';
    const fail = 'راسب ويبقى للاعادة';
    let hasEmptySubject = false;
    if (Number(periodicalId) === 4) {
      // 1=pass, 2=fail
      if (student?.studentacademicyear?.year_status === 2) {
        return fail;
      }
      subjects
        ?.filter((subject) => subject.is_essential === 1)
        .map(subject => {
          const subjectEndOfYearResult = student.student_subject_total_periodicals_mark.find(i => i.subject_id === subject.id)?.mark;
          if (student?.[table].filter(i => i.subject_id === subject.id && i.mark !== null).length !== 4) {
            hasEmptySubject = true;
          } else {
            if (subjectEndOfYearResult < 55) {
              absentSubject += " " + subject.ar_name + ' / ' ;
              countOfAbsentSubject += 1;
            }
          }
        });
      if (hasEmptySubject) {
        return '----------------';
      }
      if (countOfAbsentSubject > 0 &&  countOfAbsentSubject < 4) {
        return  absentSubject.substring(0, absentSubject.length - 2) ;
      } else if (countOfAbsentSubject > 3) {
        return fail;
      } else {
        return 'ناجح ' + numberArabicWords[parseInt(student.studentacademicyear.schoolgrade.name.replace(/[^0-9]/g, '')) + 1];
      }
    } else {
      return '----------------';
    }
  };

  static finalPoints = (student, subjects, periodicalId) => {
    let points = null;
    let hasEmptySubject = false;
    if (Number(periodicalId) === 4) {
      subjects
        ?.filter((subject) =>
          student?.StudentTracks?.find(el => el.SchoolTrack.academic_year_id == subjects[0]?.academic_year_id)?.SchoolTrack?.SchoolSubjects.map(
            (i) => i.id
          )?.includes(subject.id)
        )
        ?.filter((subject) => subject.is_essential === 1)
        .map(subject => {
          const subjectResult = this.endOfYearResult(student, subject.id);
          const gradingPoint = gradingPoints.find(gradingPoint =>
            (subject.subject_system_type_id === null ? true : subject.subject_system_type_id == gradingPoint.subject_system_type_id)
            &&
            (parseFloat(subjectResult) >= parseFloat(gradingPoint.marks_from) && parseFloat(subjectResult) <= parseFloat(gradingPoint.marks_to))
          );
          if (gradingPoint) {
            points += Number(Math.round(gradingPoint.points * subject.subject_type_credit + 'e' + 2) + 'e-' + 2);
          }
          if(isNaN(subjectResult)){
            hasEmptySubject = true;
          }
        });
    }
    points = Number(Math.round(points + 'e' + 2) + 'e-' + 2).toFixed(2);
    if(hasEmptySubject){
      return '-';
    }
    return points === null || isNaN((points / this.getTotalCredit(student, subjects))) ? '-' : (points / this.getTotalCredit(student, subjects)).toFixed(2);
  };

  static getTotalCredit = (student, subjects) => {
    return subjects
      ?.filter((subject) =>
        student?.StudentTracks?.find(el => el.SchoolTrack.academic_year_id == subjects[0]?.academic_year_id)?.SchoolTrack?.SchoolSubjects.map(
          (i) => i.id
        )?.includes(subject.id)
      )
      ?.filter((subject) => subject.is_essential === 1)
      ?.map((subject) => parseFloat(subject.subject_type_credit))
      ?.reduce((partialSum, a) => partialSum + a, 0)
      ?.toFixed(2);
  };


  static checkPermission = (permissions, isAdmin, groupSlug, pageSlug, permissionName) => {
    return permissions
      ?.groups?.find(group => group['slug'] === groupSlug)
      ?.PermissionsPages.find(p => p.slug === pageSlug)
      ?.Permissions.find(p => p.slug === permissionName) !== undefined || isAdmin;
  };
  static getDisabledHours = (selectedHour) => {
    let hours = [];
    for (let i = 0; i < moment(selectedHour).hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  static getDisabledMinutes = (selectedMinute) => {
    let minutes = [];
    for (let i = 0; i < selectedMinute; i++) {
      minutes.push(i);
    }
    return minutes;
  }
  static setCookie = (cname,cvalue,exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + ((exdays) * 24 * 60 *60 *1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  static getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  static checkCookie = (cname) => {
    let isExists = this.getCookie(cname) != "";
    if (isExists) {
      return true
    } else {
      return false
    }
  }
}
export default Utils;
