export const GET_CLOTHES_STORE_CATEGORIES = 'GET_CLOTHES_STORE_CATEGORIES';
export const GET_CLOTHES_DISTRIBUTERS = 'GET_CLOTHES_DISTRIBUTERS';
export const GET_CLOTHES = 'GET_CLOTHES';
export const GET_PURCHASED_CLOTHES = 'GET_PURCHASED_CLOTHES';
export const GET_CLOTHES_SIZES = 'GET_CLOTHES_SIZES';
export const GET_SELLED_CLOTHES = 'GET_SELLED_CLOTHES';
export const GET_CLOTHES_WITH_COUNT = 'GET_CLOTHES_WITH_COUNT';
export const GET_REFUNDED_CLOTHES = 'GET_REFUNDED_CLOTHES';
export const GET_PROPOSAL_REQUEST = 'GET_PROPOSAL_REQUEST';
export const GET_DAILY_COLLECTOR_REPORT = 'GET_DAILY_COLLECTOR_REPORT';
export const GET_DETAILS_COLLECTOR_REPORT = 'GET_DETAILS_COLLECTOR_REPORT';
export const GET_CLOTHE_SOLD_RETURN_REPORT = 'GET_CLOTHE_SOLD_RETURN_REPORT';
export const GET_INVENTORT_CLOTHES_REPORT = 'GET_INVENTORT_CLOTHES_REPORT';
export const GET_CLOTHES_DISTRIBUTERS_REPORTS = 'GET_CLOTHES_DISTRIBUTERS_REPORTS';
export const GET_PRODUCT_MOVMENT_REPORTS = 'GET_PRODUCT_MOVMENT_REPORTS';




